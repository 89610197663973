import { IPreOrderItem } from 'oci-models/basket/preOrderItem.model';
import { AuthHelper } from 'mp-utils/authHelper.util';
import { IOciDataForUpdateBasket } from 'modules/oci/models/services/ociDataForUpdateBasket.model';
import { IOciBasket } from 'oci-models/basket/basket.model';
import { IOciReturnOrderItem } from 'oci-models/services/api/ociReturnOrderItem.model';
import { IOciBasketStatus } from 'oci-models/services/api/ociBasketStatus.model';
import { IOciBasketItemsCountResponse } from 'modules/oci/models/services/api/ociBasketItemsCountResponse.model';
import { IOciReplaceItemData } from 'oci-models/services/api/ociReplaceItemData.model';

export class OciOrdersApiService {
  public static async addItemToPreOrder(sessionId: string, body: IPreOrderItem): Promise<IOciBasket> {
    return AuthHelper.fetch(
      `/api/v1/oci/orders/${sessionId}/items`,
      {
        method: 'POST',
        body,
      },
      true,
    );
  };

  public static async updateNeeds(sessionId: string, body: IOciDataForUpdateBasket): Promise<void> {
    return AuthHelper.fetch(
      `/api/v1/oci/orders/${sessionId}`,
      {
        method: 'POST',
        body,
      },
    );
  }

  public static async getBasket(sessionId: string): Promise<IOciBasket> {
    return AuthHelper.fetch(
      `/api/v1/oci/orders/${sessionId}`,
      { method: 'GET' },
    );
  }

  public static async deleteItem(sessionId: string, itemId: number): Promise<IOciBasket> {
    return AuthHelper.fetch(
      `/api/v1/oci/orders/${sessionId}/items/${itemId}`,
      { method: 'DELETE' },
    );
  }

  public static async updateItem(sessionId: string, itemId: number, body: IPreOrderItem): Promise<IOciBasket> {
    return AuthHelper.fetch(
      `/api/v1/oci/orders/${sessionId}/items/${itemId}`,
      {
        method: 'PATCH',
        body,
      },
    );
  }

  public static async deleteErpPosition(sessionId: string, erpPositionId: number): Promise<IOciBasket> {
    return AuthHelper.fetch(
      `/api/v1/oci/orders/${sessionId}/erp_positions/${erpPositionId}`,
      { method: 'DELETE' },
    );
  }

  public static async undeleteErpPosition(sessionId: string, erpPositionId: number): Promise<IOciBasket> {
    return AuthHelper.fetch(
      `/api/v1/oci/orders/${sessionId}/erp_positions/${erpPositionId}/undelete`,
      { method: 'PATCH' },
    );
  }

  public static async returnOrder(sessionId: string): Promise<Array<IOciReturnOrderItem>> {
    return AuthHelper.fetch(
      `/api/v1/oci/orders/${sessionId}/return_order`,
      { method: 'POST' },
    );
  }

  public static async returnOrderV2(sessionId: string, domainName: string): Promise<Array<IOciReturnOrderItem>> {
    return AuthHelper.fetch(
        `/api/v2/oci/orders/${sessionId}/return_order`,
        { method: 'POST', body: { domainName } },
    );
  }

  public static async getBasketStatus(sessionId: string): Promise<IOciBasketStatus> {
    return AuthHelper.fetch(
      `/api/v1/oci/orders/${sessionId}/status`,
      { method: 'GET' },
    );
  }

  public static async deleteItems(sessionId: string): Promise<IOciBasket> {
    return AuthHelper.fetch(
      `/api/v1/oci/orders/${sessionId}/items`,
      { method: 'DELETE' },
    );
  }

  public static async getBasketItemsCounts(sessionId: string): Promise<IOciBasketItemsCountResponse> {
    return AuthHelper.fetch(
      `/api/v1/oci/orders/${sessionId}/items/count`,
      { method: 'GET' },
    );
  }

  public static async replaceItem(
    sessionId: string,
    itemId: number,
    body: IOciReplaceItemData,
  ): Promise<IOciBasket> {
    return AuthHelper.fetch(
      `/api/v1/oci/orders/${sessionId}/items/${itemId}/replace`,
      {
        method: 'PATCH',
        body,
      },
    );
  }
}
