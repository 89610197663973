import { ISapParams } from 'oci-models/sapParams.model';
import { IOciNeedForUpdateBasket } from 'oci-models/services/ociNeedForUpdateBasket.model';

export const ociQueryParamNeedMap = new Map<keyof ISapParams, keyof IOciNeedForUpdateBasket>([
  ['rsnum', 'erpDoc'],
  ['rspos', 'erpDocPosition'],
  ['autyp', 'type'],
  ['matnr', 'mtrCode'],
  ['matkx', 'mtrName'],
  ['meins', 'unitType'],
  ['bdmng', 'quantity'],
  ['bdter', 'planDate'],
]);
