import { ProviderService } from 'shared/models/providerService.model';
import { H3Event } from 'h3';
import { ISapParams } from 'oci-models/sapParams.model';
import { getQueryPageEvent } from 'shared/composables/getQueryPageEvent';
import { getMethodPageEvent } from 'shared/composables/getMethodPageEvent';
import { getBodyPageEvent } from 'shared/composables/getBodyPageEvent';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { ESapServiceParamKey } from 'oci-enums/services/sapServiceParamKey.enum';
import { OciAuthApiService } from 'oci-services/api/ociAuthApi.service';
import { useUserStore } from 'mp-store/user.store';
import { IOciNeedForUpdateBasket } from 'oci-models/services/ociNeedForUpdateBasket.model';
import { ociQueryParamNeedMap } from 'oci-constants/ociQueryParamNeedMap.const';
import { OciOrdersApiService } from './api/ociOrdersApi.service';
import { RequestHelper } from 'shared/utils/requestHelper.util';
import { subdomainClientIdMap } from 'oci-constants/subdomainClientIdMap.const';
import { BasesApiService } from 'oci-services/api/basesApi.service';
import { IOciAuthResponse } from 'oci-models/services/api/ociAuthResponse.model';
import { IDeliveryBase } from 'shared/models/bases.model';
import { ociItemTypeMap } from 'oci-constants/ociItemTypeMap.const';
import { sendRedirect } from 'h3';

export class OciAuthService extends ProviderService {
  protected static readonly serviceName = 'ociAuthService';

  private parsedSapParams: ISapParams = {};
  private base: IDeliveryBase | undefined;
  private queryMethodParamParserMap = new Map<string, (event: H3Event) => Promise<undefined | ISapParams>>([
    ['GET', this.parseSapQueryParams],
    ['POST', this.parseSapBodyParams],
  ]);
  private logging = false;

  constructor(
    private readonly userStore = useUserStore(),
    private readonly runtimeConfig = useRuntimeConfig(),
    private readonly logger = process.server ? useNuxtApp()?.$logger : undefined,
  ) {
    super();

    this.checkLogging();
  }

  public getBase(): IDeliveryBase | undefined {
    return this.base;
  }

  public async parseSapParams(event: H3Event): Promise<ISapParams | undefined> {
    const parser = this.queryMethodParamParserMap.get(getMethodPageEvent(event).toUpperCase());
    return parser ? await parser.call(this, event) : await this.parseSapBodyParams(event);
  }

  public async getSession(): Promise<IOciAuthResponse | undefined> {
    try {
      const authResponse = await OciAuthApiService.auth();
      this.userStore.setOciAuth(authResponse.sessionId, authResponse.sessionTransactionId);
      return authResponse;
    } catch (error) {
      clientSentry.captureServiceException(error, OciAuthService.getServiceName());
      throw 'Не удалось получить сессию';
    }
  }

  public async updateBasketByReceivedNeeds(event: H3Event, sapParams: ISapParams): Promise<void> {
    const preparedNeeds = this.prepareReceiveNeeds(sapParams);
    const basisId = Number(sapParams.addrn?.[0]);
    const clientId = this.getClientIdByDomain(event);

    if (!this.userStore.sessionId) {
      clientSentry.captureServiceException(
        new Error('updateBasketByReceivedNeeds. Не найден sessionId'),
        OciAuthService.getServiceName(),
      );
      throw 'Ошибка в получении данных OCI авторизации';
    }

    if (!basisId) {
      clientSentry.captureServiceException(
        new Error('updateBasketByReceivedNeeds. Не найден basisId'),
        OciAuthService.getServiceName(),
        undefined,
        {
          extra: {
            sapParams,
          },
        },
      );
      throw new Error('Базис не определен');
    }

    if (!clientId) {
      clientSentry.captureServiceException(
        new Error('updateBasketByReceivedNeeds. Не определен clientId по поддомену'),
        OciAuthService.getServiceName(),
        undefined,
        {
          extra: {
            host: RequestHelper.getRequestHost(event),
            url: RequestHelper.getRequestURL(event),
            event,
          },
        },
      );
      throw new Error('Не определен индентификатор клиента');
    }

    try {
      await OciOrdersApiService.updateNeeds(
        this.userStore.sessionId,
        {
          basisId,
          clientId,
          useMtrPrice: true,
          useAllowedCategory: true,
          items: preparedNeeds,
        },
      );
    } catch (error) {
      this.logger?.error({
        serviceName: OciAuthService.getServiceName(),
        errorMessage: error?.message || error,
        basisId,
        clientId,
        preparedNeeds,
      });

      clientSentry.captureServiceException(
        error,
        OciAuthService.getServiceName(),
        undefined,
        {
          extra: {
            event,
            clientId,
            basisId,
            sessionId: this.userStore.sessionId,
            items: preparedNeeds,
          },
        },
      );
      throw 'Ошибка при создании корзины';
    }
  }

  private async parseSapQueryParams(event: H3Event): Promise<ISapParams | undefined> {
    if (!event) {
      return;
    }

    let query: Record<string, unknown>;

    try {
      query = await getQueryPageEvent(event);
    } catch (error) {
      clientSentry.captureServiceException(error, OciAuthService.getServiceName());
      throw 'Ошибка парсинга параметров';
    }

    this.logRequest(event, query, 'GET');

    return this.mapReceivedParams(query);
  }

  private async parseSapBodyParams(event: H3Event): Promise<ISapParams | undefined> {
    if (!event) {
      return;
    }

    let body: Record<string, unknown>;

    try {
      body = await getBodyPageEvent(event) as Record<string, unknown>;
    } catch (error) {
      clientSentry.captureServiceException(error, OciAuthService.getServiceName());
      throw 'Ошибка парсинга параметров';
    }

    this.logRequest(event, body, 'POST');

    return this.mapReceivedParams(body);
  }

  private mapReceivedParams(params?: Record<string, unknown>): ISapParams | undefined {
    if (!params) {
      return;
    }

    Object.entries(ESapServiceParamKey).map(([key, value]) => {
      const param = params?.[key];
      this.parsedSapParams[value as keyof ISapParams] = (Array.isArray(param) ? param : param && [param]) as string[];
    });

    return this.parsedSapParams;
  }

  private prepareReceiveNeeds(sapParams: ISapParams): Array<IOciNeedForUpdateBasket> {
    const needsLength = Math.max(
      ...Object.entries(sapParams)
        .filter(([key]) => key !== ESapServiceParamKey.HOOK_URL)
        .map(([, value]) => value)
        .map((value) => value?.length)
        .filter((value) => value),
      0,
    );
    const needs: Array<IOciNeedForUpdateBasket> = [];

    for (let i = 0; i < needsLength; i++) {
      const need: IOciNeedForUpdateBasket = {};
      Object.entries(sapParams).map(([key, value]) => {
        const needKey = ociQueryParamNeedMap.get(key as keyof ISapParams);
        if (!needKey) {
          return;
        }

        need[needKey as keyof IOciNeedForUpdateBasket] = needKey === 'type'
          ? ociItemTypeMap.get(value?.[i]) || value?.[i]
          : value?.[i];
      });

      needs.push(need);
    }

    return needs;
  }

  public getClientIdByDomain(event: H3Event): number | undefined {
    const { public: { appHost } } = this.runtimeConfig || { public: {} };
    const cleanSubdomain = RequestHelper.getSubdomain(event, appHost);
    return subdomainClientIdMap.get(cleanSubdomain);
  }

  public async initBase(sapParams: ISapParams): Promise<void> {
    const baseId = Number(sapParams?.[ESapServiceParamKey.ADDRN]?.[0]);
    if (!baseId) {
      throw 'Базис не найден в переданных из SAP параметрах';
    }

    try {
      this.base = await BasesApiService.getBaseById(baseId);
      this.userStore.setSelectedBase(this.base);
    } catch (error) {
      clientSentry.captureServiceException(error, OciAuthService.getServiceName());
      throw 'Ошибка при запросе базиса';
    }
  }

  public isSapParamsEmpty(sapParams?: ISapParams): boolean {
    const values = Object.values(sapParams || {});
    return !sapParams || !values.length || !values.some((value) => value);
  }

  public async clearUrl(event: H3Event): Promise<void> {
    await sendRedirect(event, event.node.req.url?.split('?')?.[0] ?? '/oci/categories/', 301);
  }

  private logRequest(event: H3Event, params: Record<string, unknown>, method: 'POST' | 'GET'): void {
    if (!this.logging || !params || !Object.entries(params)?.length) {
      return;
    }

    const url = this.makeRequestURL(event, params, method);

    this.logger?.error(JSON.stringify({
      serviceName: 'ociAuthService',
      message: 'sapRequest',
      link: url.toString(),
      pathWithSearch: `${url.pathname}${url.searchParams}`,
      originalParams: params,
      method,
    }));
  }

  private makeRequestURL(event: H3Event, params: Record<string, unknown>, method: 'POST' | 'GET'): URL {
    const url = RequestHelper.getRequestURL(event);
    if (method === 'GET') {
      return url;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((valueItem) => url.searchParams.append(key, valueItem as string));
      } else if (typeof value === 'string') {
        value.split(',')?.forEach((valueItem) => url.searchParams.append(key, valueItem as string));
      } else {
        url.searchParams.append(key, value as string);
      }
    });

    return url;
  }

  private checkLogging(): void {
    const { ociRequestLog } = (this.runtimeConfig || {});
    this.logging = !!Number(ociRequestLog);
  }
}
