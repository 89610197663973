export enum ESapServiceParamKey {
  ADDRN = 'addrn',
  RSNUM = 'rsnum',
  RSPOS = 'rspos',
  AUTYP = 'autyp',
  MATNR = 'matnr',
  MATKX = 'matkx',
  MEINS = 'meins',
  BDMNG = 'bdmng',
  BDTER = 'bdter',
  HOOK_URL = 'hookUrl',
}
