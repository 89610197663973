import { H3Event } from 'h3';

export class RequestHelper {
  /**
   * Проверка есть ли хедер, который определяет что запрос из внутренней сети
   * @param requestHeaders
   */
  static hasXInternalNetwork(requestHeaders: Record<string, unknown>): boolean {
    return (requestHeaders?.['X-Internal-Network'] || requestHeaders?.['x-internal-network']) === '1';
  }

  public static getRequestHost(event: H3Event) {
    const xForwardedHost = event.node.req.headers['x-forwarded-host'] as string;
    if (xForwardedHost) {
      return xForwardedHost;
    }

    return event.node.req.headers.host || 'localhost';
  }

  public static getRequestProtocol(event: H3Event) {
    if (event.node.req.headers['x-forwarded-proto'] === 'https') {
      return 'https';
    }

    return (event.node.req.connection as unknown as { encrypted: boolean }).encrypted ? 'https' : 'http';
  }

  public static getRequestURL(event: H3Event) {
    const host = this.getRequestHost(event);
    const protocol = this.getRequestProtocol(event);

    return new URL(event.path || '/', `${protocol}://${host}`);
  }

  public static getSubdomain(event: H3Event, appHost: string): string {
    return RequestHelper.getRequestHost(event)
      .replace(appHost, '')
      .replace('www.', '')
      .replace(/[:.].*$/, '');
  }
}
